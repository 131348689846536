import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const TrialActivationModal = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    activateFreeTrial();
  }, []);

  const activateFreeTrial = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/activate-free-trial`,
        {},
        {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        }
      );

      if (response.data.init_point) {
        // Redirigir al usuario a la página de pago de Mercado Pago
        window.location.href = response.data.init_point;
      } else {
        setError('Error al crear la preferencia de pago');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Error al iniciar el período de prueba');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg max-w-md w-full text-center">
          <p className="text-lg">Preparando tu período de prueba...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg max-w-md w-full">
          <h2 className="text-2xl font-bold mb-4 text-red-600">Error</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <button 
            onClick={onClose}
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded w-full"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }

  return null; // No renderizamos nada si la redirección fue exitosa
};