import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Star, Loader2 } from 'lucide-react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { AlertCircle } from 'lucide-react';
import { TrialActivationModal } from './TrialActivationModal';

import 'react-toastify/dist/ReactToastify.css';



const plans = [
  {
    id: 'foco',
    name: 'Plan Foco',
    price: 5.99,
    description: 'Plan inicial para comenzar a construir tu marca',
    features: [
      'Definición de tu perfil personal y tus objetivos',
      'Generación de contenidos mediante inteligencia artificial',
      'Planificación posteos en los mejores días y horarios',
      'Publicaciones en LinkedIn automáticas',
      'Aprendizaje automático con IA generativa'
    ],
    color: 'bg-[#8B8000]'
  },
  {
    id: 'potencia',
    name: 'Plan Potencia',
    price: 10.99,
    description: 'Para profesionales en crecimiento',
    features: [
      'Definición de tu perfil peronal y tus objetivos',
      'Generación de contenidos mediante inteligencia artificial',
      'Planificación posteos en los mejores días y horarios',
      'Publicaciones en LinkedIn automáticas',
      'Aprendizaje automático con IA generativa',
      'Test de Personalidad',
      'Creación de hasta 4 Post individuales mediante IA'
    ],
    color: 'bg-cyan-600',
    popular: true
  },
  {
    id: 'brillante',
    name: 'Plan Brillante',
    price: 49.99,
    description: 'Máximo impacto y resultados',
    features: [
      'Definición de tu perfil personal y tus objetivos',
      'Generación de contenidos mediante inteligencia artificial',
      'Planificación posteos en los mejores días y horarios',
      'Publicaciones en LinkedIn automáticas',
      'Aprendizaje automático con IA generativa',
      'Test de Personalidad',
      'Creación de hasta 4 Post individuales mediante IA',
      'Asesoría personalizada',
      'Soporte prioritario'
    ],
    color: 'bg-purple-600'
  }
];
// Helper Functions
const handleSubscribe = async (plan, token, discount = 0) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/subscriptions/create`,
      {
        plan_id: plan.id,
        discount: discount
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data.init_point && response.data.subscription_id) {
      localStorage.setItem('pending_subscription_id', response.data.subscription_id);
      return response.data;
    }
    throw new Error('Error al crear la preferencia de pago');
  } catch (error) {
    console.error('Error al crear la suscripción:', error);
    throw error;
  }
};


const FormFields = ({ formData, setFormData, onContinue }) => {
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    
    // Special handling for birthDate to ensure proper formatting
    if (name.startsWith('birthDate')) {
      const [field, part] = name.split('-');
      const currentBirthDate = formData.birthDate || '----';
      const parts = currentBirthDate.split('-');
      
      switch (part) {
        case 'day':
          parts[2] = value;
          break;
        case 'month':
          parts[1] = value;
          break;
        case 'year':
          parts[0] = value;
          break;
      }
      
      setFormData({ 
        ...formData, 
        birthDate: parts.join('-')
      });
    } 
    // Checkbox handling for goals
    else if (type === 'checkbox') {
      setFormData({
        ...formData,
        goals: checked
          ? [...(formData.goals || []), value]
          : (formData.goals || []).filter((g) => g !== value)
      });
    } 
    // Regular input handling
    else {
      setFormData({ 
        ...formData, 
        [name]: value 
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate form data
    if (!formData.birthDate || !formData.gender || !formData.nationality || !formData.industry || (formData.goals || []).length === 0) {
      toast.error('Por favor completa todos los campos requeridos');
      return;
    }
    
    // Age validation
    const [year, month, day] = formData.birthDate.split('-').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    
    if (age < 18) {
      toast.error('Debes tener al menos 18 años para registrarte');
      return;
    }
    
    // If validation passes, continue to next step
    onContinue();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Birth date */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">Fecha de nacimiento</label>
        <div className="flex space-x-2">
          <select
            name="birthDate-day"
            value={(formData.birthDate || '----').split('-')[2] || ''}
            onChange={handleInputChange}
            className="w-1/3 bg-gray-700 text-white rounded-lg p-3"
            required
          >
            <option value="">Día</option>
            {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
              <option key={day} value={String(day).padStart(2, '0')}>
                {day}
              </option>
            ))}
          </select>
          <select
            name="birthDate-month"
            value={(formData.birthDate || '----').split('-')[1] || ''}
            onChange={handleInputChange}
            className="w-1/3 bg-gray-700 text-white rounded-lg p-3"
            required
          >
            <option value="">Mes</option>
            {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
              <option key={month} value={String(month).padStart(2, '0')}>
                {month}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="birthDate-year"
            value={(formData.birthDate || '----').split('-')[0] || ''}
            onChange={handleInputChange}
            placeholder="Año"
            className="w-1/3 bg-gray-700 text-white rounded-lg p-3"
            required
            maxLength="4"
            pattern="\d{4}"
          />
        </div>
      </div>

      {/* Gender */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">Género</label>
        <select
          name="gender"
          value={formData.gender || ''}
          onChange={handleInputChange}
          className="w-full bg-gray-700 text-white rounded-lg p-3"
          required
        >
          <option value="">Seleccionar...</option>
          <option value="male">Hombre</option>
          <option value="female">Mujer</option>
          <option value="other">Otro</option>
        </select>
      </div>

      {/* Nationality */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">Nacionalidad</label>
        <select
          name="nationality"
          value={formData.nationality || ''}
          onChange={handleInputChange}
          className="w-full bg-gray-700 text-white rounded-lg p-3"
          required
        >
          <option value="">Seleccionar...</option>
          <option value="argentina">Argentina</option>
          <option value="bolivia">Bolivia</option>
          <option value="brazil">Brasil</option>
          <option value="chile">Chile</option>
          <option value="colombia">Colombia</option>
          <option value="costa-rica">Costa Rica</option>
          <option value="cuba">Cuba</option>
          <option value="ecuador">Ecuador</option>
          <option value="el-salvador">El Salvador</option>
          <option value="guatemala">Guatemala</option>
          <option value="haiti">Haití</option>
          <option value="honduras">Honduras</option>
          <option value="mexico">México</option>
          <option value="nicaragua">Nicaragua</option>
          <option value="panama">Panamá</option>
          <option value="paraguay">Paraguay</option>
          <option value="peru">Perú</option>
          <option value="dominican-republic">República Dominicana</option>
          <option value="uruguay">Uruguay</option>
          <option value="venezuela">Venezuela</option>
          <option value="other">Otro país latinoamericano</option>
        </select>
      </div>

      {/* Industry */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">Sector de trabajo</label>
        <select
          name="industry"
          value={formData.industry || ''}
          onChange={handleInputChange}
          className="w-full bg-gray-700 text-white rounded-lg p-3"
          required
        >
          <option value="">Seleccionar...</option>
          <option value="technology">Tecnológico</option>
          <option value="business">Negocios y Emprendimiento</option>
          <option value="health">Salud y Bienestar</option>
          <option value="education">Educación</option>
          <option value="marketing">Marketing Digital</option>
          <option value="other">Otros</option>
        </select>
      </div>

      {/* Goals */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-1">
          ¿Qué deseas lograr con tu marca personal?
        </label>
        <div className="space-y-2">
          {[
            'Aumentar visibilidad profesional',
            'Conseguir mejores oportunidades laborales',
            'Establecerme como referente en mi industria',
            'Atraer nuevos clientes',
            'Crear una comunidad',
            'Compartir conocimiento'
          ].map((goal) => (
            <label key={goal} className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="goals"
                value={goal}
                checked={(formData.goals || []).includes(goal)}
                onChange={handleInputChange}
                className="form-checkbox h-5 w-5 text-purple-500"
              />
              <span className="text-gray-300">{goal}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <button
          type="submit"
          className="bg-purple-500 hover:bg-purple-600 text-white px-6 py-2 rounded-lg"
        >
          Continuar
        </button>
      </div>
      <ToastContainer />
    </form>
  );
};
const PlanCard = ({ plan, isActive, token }) => {
  const handlePaymentClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/subscriptions/create`,
        {
          plan_id: plan.id,
          discount: 0
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.init_point && response.data.subscription_id) {
        localStorage.setItem('pending_subscription_id', response.data.subscription_id);
        window.location.href = response.data.init_point;
      } else {
        throw new Error('Error al crear la preferencia de pago');
      }
    } catch (error) {
      console.error('Error al crear la suscripción:', error);
      toast.error('Error al procesar el pago. Por favor, intenta nuevamente.');
    }
  };

  return (
    <div 
      className={`
        w-[85vw]
        min-w-[250px]
        max-w-[300px]
        sm:max-w-[320px]
        md:max-w-[340px]
        lg:max-w-[360px]
        h-[400px]
        sm:h-[420px]
        md:h-[440px]
        lg:h-[460px]
        bg-[#1E2330] 
        rounded-lg 
        overflow-hidden 
        transition-all 
        duration-500 
        flex 
        flex-col
        mx-auto
        ${isActive ? 'opacity-100 scale-100' : 'opacity-40 scale-95'}
      `}
    >
      {/* Header con precio */}
      <div className={`${plan.color} p-2 relative flex items-center justify-between flex-shrink-0`}>
        <div className="flex-1 pr-2">
          <h3 className="text-sm font-medium text-white line-clamp-1">{plan.name}</h3>
          <p className="text-[10px] sm:text-xs text-white/80 line-clamp-1">{plan.description}</p>
        </div>
        <div className="flex-shrink-0 bg-white rounded-full px-1.5 py-0.5 flex items-center">
          <span className="text-xs text-gray-500 font-bold">{plan.price}</span>
          <span className="text-xs text-gray-500 font-bold ml-0.5">USD</span>
        </div>
      </div>

      {/* Contenido principal */}
      <div className="flex-1 p-2 flex flex-col min-h-0 overflow-hidden">
        <div className="mb-2">
          <h4 className="text-white text-[10px] sm:text-xs font-medium mb-1">BENEFICIOS</h4>
          <ul className="space-y-0.5 overflow-y-auto max-h-[180px] scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            {plan.features.map((feature, index) => (
              <li 
                key={index} 
                className="text-gray-400 text-[10px] sm:text-xs leading-tight flex items-start"
              >
                <span className="mr-1 flex-shrink-0">•</span>
                <span className="line-clamp-2">{feature}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-auto">
          <h4 className="text-white text-[10px] sm:text-xs font-medium mb-1">CONDICIONES</h4>
          <ul className="space-y-0.5">
            {[
              'Compromiso mínimo de 3 meses',
              'Cancela cuando quieras',
              'Renovación automática por el mismo período'
            ].map((condition, index) => (
              <li 
                key={index}
                className="text-gray-400 text-[10px] sm:text-xs leading-tight flex items-start"
              >
                <span className="mr-1 flex-shrink-0">•</span>
                <span className="line-clamp-1">{condition}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Botón de pago */}
      <div className="p-2 bg-[#1E2330] border-t border-gray-800 flex-shrink-0">
        <button
          className="
            w-full
            bg-white 
            hover:bg-gray-100 
            rounded-lg 
            py-1.5
            px-2
            transition-colors 
            flex 
            items-center 
            justify-center 
            gap-1.5
            border 
            border-gray-200 
            shadow-sm
          "
          onClick={handlePaymentClick}
        >
          <span className="text-[10px] sm:text-xs text-gray-600 font-medium">Ir a</span>
          <img
            src="https://logotipoz.com/wp-content/uploads/2021/10/version-horizontal-large-logo-mercado-pago.webp"
            alt="MercadoPago"
            className="h-3 sm:h-4"
          />
        </button>
      </div>
    </div>
  );
};
const Plans = ({ onSelectPlan }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [token, setToken] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        if (storedToken) setToken(storedToken);
        else throw new Error('No se encontró el token de autenticación');
      } catch (error) {
        console.error('Error al obtener el token:', error);
        toast.error('Error al obtener el token de autenticación');
      }
    };
    fetchToken();
  }, []);

  const handlePrev = () => setActiveIndex(prev => (prev === 0 ? plans.length - 1 : prev - 1));
  const handleNext = () => setActiveIndex(prev => (prev === plans.length - 1 ? 0 : prev + 1));


  const getCardStyles = (index) => {
    if (isMobile) {
      return index === activeIndex 
        ? {
            transform: 'translate(-50%, 0) scale(1)',
            left: '50%',
            top: '0',
            zIndex: 30,
            opacity: 1
          }
        : {
            transform: 'translate(-50%, 0) scale(0.8)',
            left: '50%',
            top: '0',
            zIndex: 10,
            opacity: 0
          };
    }

    const position = (index - activeIndex + plans.length) % plans.length;
    
    if (position === 0) {
      return {
        transform: 'translate(-50%, -50%) scale(1)',
        left: '50%',
        top: '50%',
        zIndex: 30
      };
    } else if (position === 1) {
      return {
        transform: 'translate(-10%, -50%) scale(0.9)',
        left: '50%',
        top: '50%',
        zIndex: 20
      };
    } else if (position === plans.length - 1) {
      return {
        transform: 'translate(-90%, -50%) scale(0.9)',
        left: '50%',
        top: '50%',
        zIndex: 20
      };
    }
    
    return {
      transform: 'translate(-50%, -50%) scale(0.8)',
      left: position < plans.length / 2 ? '-50%' : '150%',
      top: '50%',
      zIndex: 10,
      opacity: 0
    };
  };
  return (
    <div className="flex flex-col items-center w-full max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
      {/* Logo con tamaño máximo */}
      <div className="w-full flex justify-center">
        <img
          src="https://vos.personalmarka.com/personal-marka.png"
          alt="Personal MARKA"
          className="h-14 sm:h-12 object-contain transform -translate-y-3"
        />
      </div>

      {/* Contenedor de cards */}
      <div className="relative w-full h-[55vh] sm:h-[480px]">
        {/* Cards */}
        {plans.map((plan, index) => (
          <div
            key={plan.id}
            className="absolute transition-all duration-500 w-full flex justify-center"
            style={getCardStyles(index)}
          >
            <PlanCard plan={plan} isActive={index === activeIndex} token={token} />
          </div>
        ))}

        {/* Navigation buttons - Desktop */}
        <button
          onClick={handlePrev}
          className="absolute left-1/2 -translate-x-[280px] top-[25%] -translate-y-1/2 z-50
            bg-gray-800/50 hover:bg-gray-800/70 p-2 rounded-full transition-colors pointer-events-auto
            sm:block hidden"
        >
          <ChevronLeft className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
        </button>

        <button
          onClick={handleNext}
          className="absolute left-1/2 translate-x-[240px] top-[25%] -translate-y-1/2 z-50
            bg-gray-800/50 hover:bg-gray-800/70 p-2 rounded-full transition-colors pointer-events-auto
            sm:block hidden"
        >
          <ChevronRight className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
        </button>

        {/* Mobile navigation buttons */}
        <div className="sm:hidden flex justify-between absolute -left-12 -right-12 top-[65px] z-50 px-4">
          <button
            onClick={handlePrev}
            className="bg-gray-800/50 hover:bg-gray-800/70 p-2 rounded-full transition-colors translate-x-[-50%]"
          >
            <ChevronLeft className="w-5 h-5 text-white" />
          </button>
          <button
            onClick={handleNext}
            className="bg-gray-800/50 hover:bg-gray-800/70 p-2 rounded-full transition-colors translate-x-[50%]"
          >
            <ChevronRight className="w-5 h-5 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};
const Onboarding = ({ onComplete }) => {
  const [formData, setFormData] = useState({
    birthDate: '',
    gender: '',
    nationality: '',
    industry: '',
    goals: []
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.birthDate || !formData.gender || !formData.nationality || !formData.industry || formData.goals.length === 0) {
      toast.error('Por favor completa todos los campos requeridos');
      return;
    }
    onComplete?.(formData);
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        goals: checked
          ? [...formData.goals, value]
          : formData.goals.filter((g) => g !== value)
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[#1E2330] rounded-xl p-8 w-full max-w-lg mx-4">
        {/* Logo */}
        <div className="flex justify-center mb-6">
          <img 
            src="https://vos.personalmarka.com/personal-marka.png" 
            alt="Personal MARKA" 
            className="h-8"
          />
        </div>

        {/* Título y subtítulo */}
        <div className="text-center mb-8">
          <h2 className="text-xl text-white font-medium">
            Bienvenido a PersonalMARKA
          </h2>
          <p className="text-gray-400 text-sm mt-2">
            Vamos a configurar tu cuenta para obtener los mejores resultados
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Fecha de nacimiento */}
          <div>
            <label className="block text-sm text-gray-400 mb-2">Fecha de nacimiento</label>
            <div className="flex space-x-2">
              <select
                name="birthDate-day"
                value={(formData.birthDate || '').split('-')[2] || ''}
                onChange={handleInputChange}
                className="w-1/3 bg-transparent border border-gray-600 text-white rounded p-2 text-sm focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              >
                <option value="">Día</option>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                  <option key={day} value={String(day).padStart(2, '0')}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                name="birthDate-month"
                value={(formData.birthDate || '').split('-')[1] || ''}
                onChange={handleInputChange}
                className="w-1/3 bg-transparent border border-gray-600 text-white rounded p-2 text-sm focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              >
                <option value="">Mes</option>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={String(month).padStart(2, '0')}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                name="birthDate-year"
                value={(formData.birthDate || '').split('-')[0] || ''}
                onChange={handleInputChange}
                className="w-1/3 bg-transparent border border-gray-600 text-white rounded p-2 text-sm focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              >
                <option value="">Año</option>
                {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Género */}
          <div>
            <label className="block text-sm text-gray-400 mb-2">Género</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className="w-full bg-transparent border border-gray-600 text-white rounded p-2 text-sm focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
            >
              <option value="">Seleccionar</option>
              <option value="male">Hombre</option>
              <option value="female">Mujer</option>
              <option value="other">Otro</option>
            </select>
          </div>

          {/* Nacionalidad */}
          <div>
            <label className="block text-sm text-gray-400 mb-2">Nacionalidad</label>
            <select
              name="nationality"
              value={formData.nationality}
              onChange={handleInputChange}
              className="w-full bg-transparent border border-gray-600 text-white rounded p-2 text-sm focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
            >
              <option value="">Seleccionar</option>
              <option value="argentina">Argentina</option>
              <option value="bolivia">Bolivia</option>
              <option value="brazil">Brasil</option>
              <option value="chile">Chile</option>
              <option value="colombia">Colombia</option>
              <option value="other">Otro país</option>
            </select>
          </div>

          {/* Sector de trabajo */}
          <div>
            <label className="block text-sm text-gray-400 mb-2">Sector de trabajo</label>
            <select
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
              className="w-full bg-transparent border border-gray-600 text-white rounded p-2 text-sm focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
            >
              <option value="">Seleccionar</option>
              <option value="technology">Tecnológico</option>
              <option value="business">Negocios y Emprendimiento</option>
              <option value="health">Salud y Bienestar</option>
              <option value="education">Educación</option>
              <option value="marketing">Marketing Digital</option>
              <option value="other">Otros</option>
            </select>
          </div>

          {/* Objetivos */}
          <div>
            <label className="block text-sm text-gray-400 mb-3">
              ¿Qué quieres lograr con tu marca personal?
            </label>
            <div className="space-y-3">
              {[
                'Aumentar visibilidad profesional',
                'Conseguir mejores oportunidades laborales',
                'Establecerme como referente en mi industria',
                'Atraer nuevos clientes',
                'Crear una comunidad',
                'Compartir conocimiento'
              ].map((goal) => (
                <label key={goal} className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="goals"
                    value={goal}
                    checked={formData.goals.includes(goal)}
                    onChange={handleInputChange}
                    className="form-checkbox rounded border-gray-600 bg-transparent text-purple-500 focus:ring-purple-500 focus:ring-offset-0"
                  />
                  <span className="text-gray-300 text-sm">{goal}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Botón */}
          <div className="flex justify-center mt-8">
            <button
              type="submit"
              className="bg-white hover:bg-gray-100 text-gray-900 px-8 py-2 rounded text-sm font-medium transition-colors"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const OnboardingFlow = ({ onComplete }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    birthDate: '',
    gender: '',
    nationality: '',
    industry: '',
    goals: []
  });

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    
    if (name.startsWith('birthDate')) {
      const [, part] = name.split('-');
      const currentBirthDate = formData.birthDate || '----';
      const parts = currentBirthDate.split('-');
      
      switch (part) {
        case 'day':
          parts[2] = value;
          break;
        case 'month':
          parts[1] = value;
          break;
        case 'year':
          parts[0] = value;
          break;
        default:
          break;
      }
      
      setFormData({ 
        ...formData, 
        birthDate: parts.join('-')
      });
    } 
    else if (type === 'checkbox') {
      setFormData({
        ...formData,
        goals: checked
          ? [...(formData.goals || []), value]
          : (formData.goals || []).filter((g) => g !== value)
      });
    } 
    else {
      setFormData({ 
        ...formData, 
        [name]: value 
      });
    }
  };

  const handleContinueToPlans = () => {
    setError('');
  
    if (!formData.birthDate || !formData.gender || !formData.nationality || !formData.industry || formData.goals.length === 0) {
      setError('Por favor completa todos los campos requeridos');
      return;
    }

    const [year, month, day] = formData.birthDate.split('-').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    
    if (age < 18) {
      setError('Debes tener al menos 18 años para registrarte');
      return;
    }

    setShowTrialModal(true);
  };
  const handleTrialActivated = async () => {
    try {
      // Guardar los datos del onboarding
      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/complete-onboarding`,
        formData,
        {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        }
      );

      // Notificar que el onboarding se ha completado
      onComplete(formData);

      // Mostrar mensaje de éxito
      toast.success('¡Período de prueba activado exitosamente!');

      // Redirigir al dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error al completar el onboarding:', error);
      toast.error('Hubo un problema al activar tu período de prueba. Por favor, intenta de nuevo.');
    }
  };
  const [showTrialModal, setShowTrialModal] = useState(false);
  const handleCloseTrialModal = () => {
    setShowTrialModal(false);
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-4" style={{
      background: 'linear-gradient(169deg, #000000 0%, #0A403C 100%)'
    }}>
      <div className="bg-[#1E2330] rounded-2xl w-full max-w-[520px] p-4 sm:p-6 md:p-8 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-center">
          <img 
            src="https://vos.personalmarka.com/personal-marka.png" 
            alt="Personal MARKA" 
            className="h-6 sm:h-8 mb-6 sm:mb-8"
          />
        </div>
  
        <div className="mb-6 sm:mb-8">
          <h2 className="text-lg sm:text-xl text-white font-normal text-center">
            Bienvenido a PersonalMARKA
          </h2>
          <p className="text-gray-400 text-xs sm:text-sm mt-2 text-center">
            Vamos a configurar tu cuenta para obtener los mejores resultados
          </p>
        </div>
  
        {error && (
          <div className="mb-6 bg-red-500/10 border border-red-500 text-red-500 px-3 sm:px-4 py-2 sm:py-3 rounded-lg flex items-center gap-2">
            <AlertCircle className="h-4 sm:h-5 w-4 sm:w-5 flex-shrink-0" />
            <span className="text-xs sm:text-sm">{error}</span>
          </div>
        )}
  
        <div className="space-y-4">
          <div>
            <label className="block text-xs sm:text-sm text-gray-400 mb-1.5 sm:mb-2">Fecha de nacimiento</label>
            <div className="grid grid-cols-3 gap-1.5 sm:gap-2">
              <select
                name="birthDate-day"
                value={(formData.birthDate || '').split('-')[2] || ''}
                onChange={handleInputChange}
                className="bg-[#2A3342] border border-gray-600 text-white rounded p-2 sm:p-2.5 text-xs sm:text-sm focus:border-purple-500 focus:ring-0 appearance-none"
              >
                <option value="">Día</option>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                  <option key={day} value={String(day).padStart(2, '0')}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                name="birthDate-month"
                value={(formData.birthDate || '').split('-')[1] || ''}
                onChange={handleInputChange}
                className="bg-[#2A3342] border border-gray-600 text-white rounded p-2 sm:p-2.5 text-xs sm:text-sm focus:border-purple-500 focus:ring-0 appearance-none"
              >
                <option value="">Mes</option>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={String(month).padStart(2, '0')}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                name="birthDate-year"
                value={(formData.birthDate || '').split('-')[0] || ''}
                onChange={handleInputChange}
                className="bg-[#2A3342] border border-gray-600 text-white rounded p-2 sm:p-2.5 text-xs sm:text-sm focus:border-purple-500 focus:ring-0 appearance-none"
              >
                <option value="">Año</option>
                {Array.from({ length: 82 }, (_, i) => new Date().getFullYear() - 18 - i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
  
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-xs sm:text-sm text-gray-400 mb-1.5 sm:mb-2">Género</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                className="w-full bg-[#2A3342] border border-gray-600 text-white rounded p-2 sm:p-2.5 text-xs sm:text-sm focus:border-purple-500 focus:ring-0 appearance-none"
              >
                <option value="">Seleccionar...</option>
                <option value="male">Hombre</option>
                <option value="female">Mujer</option>
                <option value="other">Otro</option>
              </select>
            </div>
            
            <div>
              <label className="block text-xs sm:text-sm text-gray-400 mb-1.5 sm:mb-2">Nacionalidad</label>
              <select
                name="nationality"
                value={formData.nationality}
                onChange={handleInputChange}
                className="w-full bg-[#2A3342] border border-gray-600 text-white rounded p-2 sm:p-2.5 text-xs sm:text-sm focus:border-purple-500 focus:ring-0 appearance-none"
              >
                <option value="">Seleccionar...</option>
                <option value="argentina">Argentina</option>
                <option value="bolivia">Bolivia</option>
                <option value="brazil">Brasil</option>
                <option value="chile">Chile</option>
                <option value="colombia">Colombia</option>
                <option value="costa-rica">Costa Rica</option>
                <option value="cuba">Cuba</option>
                <option value="ecuador">Ecuador</option>
                <option value="el-salvador">El Salvador</option>
                <option value="guatemala">Guatemala</option>
                <option value="haiti">Haití</option>
                <option value="honduras">Honduras</option>
                <option value="mexico">México</option>
                <option value="nicaragua">Nicaragua</option>
                <option value="panama">Panamá</option>
                <option value="paraguay">Paraguay</option>
                <option value="peru">Perú</option>
                <option value="dominican-republic">República Dominicana</option>
                <option value="uruguay">Uruguay</option>
                <option value="venezuela">Venezuela</option>
                <option value="other">Otro país latinoamericano</option>
              </select>
            </div>
          </div>
  
          <div>
            <label className="block text-xs sm:text-sm text-gray-400 mb-1.5 sm:mb-2">Sector de trabajo</label>
            <select
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
              className="w-full bg-[#2A3342] border border-gray-600 text-white rounded p-2 sm:p-2.5 text-xs sm:text-sm focus:border-purple-500 focus:ring-0 appearance-none"
            >
              <option value="">Seleccionar...</option>
              <option value="technology">Tecnológico</option>
              <option value="business">Negocios y Emprendimiento</option>
              <option value="health">Salud y Bienestar</option>
              <option value="education">Educación</option>
              <option value="marketing">Marketing Digital</option>
              <option value="other">Otros</option>
            </select>
          </div>
  
          <div className="mt-4 sm:mt-6">
            <label className="block text-xs sm:text-sm text-gray-400 mb-2 sm:mb-3">
              ¿Qué deseas lograr con tu marca personal?
            </label>
            <div className="space-y-2 sm:space-y-2.5">
              {[
                'Aumentar visibilidad profesional',
                'Conseguir mejores oportunidades laborales',
                'Establecerme como referente en mi industria',
                'Atraer nuevos clientes',
                'Crear una comunidad',
                'Compartir conocimiento'
              ].map((goal) => (
                <label key={goal} className="flex items-start sm:items-center space-x-2">
                  <input
                    type="checkbox"
                    name="goals"
                    value={goal}
                    checked={formData.goals.includes(goal)}
                    onChange={handleInputChange}
                    className="form-checkbox mt-0.5 sm:mt-0 h-3.5 w-3.5 sm:h-4 sm:w-4 border-gray-600 bg-[#2A3342] text-purple-500 rounded focus:ring-0"
                  />
                  <span className="text-gray-300 text-xs sm:text-sm leading-tight">{goal}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
  
        <div className="flex justify-center mt-6 sm:mt-8">
          <button
            onClick={handleContinueToPlans}
            className="bg-white hover:bg-gray-100 text-black px-8 py-2 rounded-lg text-sm font-medium transition-colors"
          >
            Guardar
          </button>
        </div>
      </div>
  
      {showTrialModal && (
        <TrialActivationModal
          onTrialActivated={handleTrialActivated}
          onClose={handleCloseTrialModal}
        />
      )}
    </div>
  );
};  
export const SubscriptionCompletionModal = ({ isOpen, onClose, hasSubscription, subscriptionPlan, onSubscriptionComplete }) => {
  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 
        ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all duration-300`}
    >
      <div className="bg-gray-800 rounded-lg p-8 max-w-6xl w-full mx-4">
        <div>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-white">
              {hasSubscription ? "Tu plan actual" : "Selecciona tu plan"}
            </h2>
            {hasSubscription && (
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-white transition-colors"
              >
                Cerrar
              </button>
            )}
          </div>
          
          <Plans  // Cambiamos PlansCarousel por Plans 
  hasSubscription={hasSubscription}
  subscriptionPlan={subscriptionPlan}
  onSelectPlan={(plan) => {
    if (onSubscriptionComplete) {
      onSubscriptionComplete(plan);
    }
  }}
/>
        </div>
      </div>
    </div>
  );
};

const Manager = ({ children }) => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showTrialActivation, setShowTrialActivation] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          toast.error('No se encontró el token de autenticación');
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/check-onboarding`,
          {
            headers: { 
              'Authorization': `Bearer ${token}` 
            }
          }
        );

        const { 
          onboardingCompleted, 
          hasSubscription, 
          hasTrial, 
          subscriptionPlan 
        } = response.data;

        // Si no ha completado el onboarding, muestra el flujo de onboarding
        if (!onboardingCompleted) {
          setShowOnboarding(true);
        } 
        // Si ya completó onboarding pero no tiene suscripción ni trial, muestra activación de trial
        else if (!hasSubscription && !hasTrial) {
          setShowTrialActivation(true);
        }

        setHasSubscription(hasSubscription || hasTrial);
        setSubscriptionPlan(subscriptionPlan);

      } catch (error) {
        toast.error('Error al verificar estado de onboarding');
      }
    };

    checkOnboardingStatus();
  }, []);

  const handleOnboardingComplete = async (data) => {
    try {
      const token = localStorage.getItem('token');
      
      // Completar onboarding normalmente
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/complete-onboarding`,
        data,
        {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        }
      );

      // Ocultar onboarding y mostrar activación de trial
      setShowOnboarding(false);
      setShowTrialActivation(true);
    } catch (error) {
      toast.error('Error al completar el proceso de onboarding');
      console.error('Error:', error);
    }
  };

  return (
    <>
      {children}
      {showOnboarding && (
        <OnboardingFlow
          onComplete={handleOnboardingComplete}
        />
      )}
      {showTrialActivation && (
        <TrialActivationModal 
          onTrialActivated={() => {
            setShowTrialActivation(false);
            // Aquí podrías redirigir al dashboard o hacer algo después del trial
          }}
          onClose={() => {
            // Si cierra el modal de trial, muestra un mensaje
            toast.info('Debes activar el período de prueba para continuar');
          }}
        />
      )}
    </>
  );
};
export const SubscriptionPlans = Plans;  // Cambiamos PlansCarousel por Plans
export default Manager;



